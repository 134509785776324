@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
 
@font-face {
  font-family: "Mocha";
  src: url("./fonts/manuka/TestManukaCondensed-Bold-BF663c33db0536a.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Segoe";
  src: url("./fonts/segoe/SegoeTVBold.TTF")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
